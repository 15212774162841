const consts = {
  defaultIssuerId: "a2b88390-1ef5-4c87-87ab-8b0fbe130da7",
  defaultCurrencyId: "433e12b0-fc6e-4419-8903-9bff56d1a8e3",
  defaultCurrencySymbol: "GEL",
  issuerAddress: "rKiUxHQQ3y6u5WU8eBqCJtD2ZKAz77Bcb1",
  explorerTransactionsLink:
    "https://custom.xrpl.org/n1.epm-rppl.projects.epam.com/transactions",
  explorerAccountsLink:
    "https://custom.xrpl.org/n1.epm-rppl.projects.epam.com/accounts",
  cbdcWalletHost: "https://wallet.epm-rppl.projects.epam.com",
};

export default consts;
